.swiperD {
  max-width: 85%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  padding: 30px 0;
  border-top: 1px grey solid;
  border-bottom: 1px grey solid;
}

.feedbackCard {
  max-width: 380px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 0px;
  h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  p {
    width: 100%;
    background: white;
    margin-bottom: 45px;
  }

  img {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    max-width: 300px;
    margin-bottom: 0;
  }
}
@media (max-width: 500px) {
  .swiperD {
    max-width: 100%;
    padding: 30px 0;
    border-top: 1px grey solid;
    border-bottom: 1px grey solid;
  }
}

@media (max-width: 600px) {
  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    bottom: -2%;
  }
  .prevD {
    top: 99%;
  }
  .nextD {
    top: 99%;
  }
}

@media (max-width: 400px) {
  .feedbackCard {
    img {
      max-width: 222px;
    }
  }
}

.feedbackGridBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.logoBox {
  max-width: 300px;
  min-width: 150px;
  margin: 0 15px 30px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  img {
    width: 100%;
  }
}
