/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
img,
ol,
ul,
li,
input,
label,
select,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
footer,
header,
menu,
nav,
section,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  list-style: none;
  border: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.5rem;
}
