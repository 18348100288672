.aboutOurWork {
  background: black;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 130px 0;
  -webkit-clip-path: polygon(
    0 0,
    20% 2%,
    39% 1%,
    56% 3%,
    73% 0,
    91% 2%,
    100% 0%,
    100% 100%,
    81% 98%,
    62% 99%,
    43% 97%,
    25% 99%,
    12% 98%,
    0% 100%
  );
  clip-path: polygon(
    0 0,
    20% 2%,
    39% 1%,
    56% 3%,
    73% 0,
    91% 2%,
    100% 0%,
    100% 100%,
    81% 98%,
    62% 99%,
    43% 97%,
    25% 99%,
    12% 98%,
    0% 100%
  );
}

.aboutOurWorkImgBox {
  max-width: 800px;
  min-width: 200px;

  img {
    width: 100%;
  }
}

.aowMobileImg {
  display: none;
}

@media (max-width: 550px) {
  .aboutOurWork {
    -webkit-clip-path: polygon(
      0 0,
      27% 1%,
      55% 0,
      78% 1%,
      100% 0%,
      100% 99%,
      73% 98%,
      46% 99%,
      20% 99%,
      0% 100%
    );
    clip-path: polygon(
      0 0,
      27% 1%,
      55% 0,
      78% 1%,
      100% 0%,
      100% 99%,
      73% 98%,
      46% 99%,
      20% 99%,
      0% 100%
    );
  }

  .aowMobileImg {
    display: block;
  }

  .aowDesktopImg {
    display: none;
  }
}
