@use '/src/scss/animations/_desktopLoadingSpinner.scss';

.animationOnloadDelay *,
.animationOnloadDelay *:before,
.animationOnloadDelay *:after {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

html {
  font-size: 16px;
  font-family: 'montserrat';
  scroll-behavior: smooth;
}

.blockBodyScroll {
  overflow-y: hidden;
}

.mainActionButton {
  border: solid 1px transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  background: rgb(0, 153, 255);
  font-weight: 600;
  color: white;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 4px;

  a {
    text-decoration: none;
    color: inherit;
    padding: 20px;
    width: 200px;
  }

  &:hover {
    background: rgb(233, 54, 137);
    color: white;
    border: solid 1px transparent;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}

.transparentButton {
  border: solid 1px black;
  background: transparent;
  color: black;
}

.globalOverflowX {
  overflow-x: hidden;
}

.hideContentWhileLoading {
  opacity: 0;
}

.showContentWhenLoaded {
  opacity: 1;
  -webkit-transition: ease-in 0.5s;
  transition: ease-in 0.5s;
}

.globalWrapper {
  max-width: 1530px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.globbalPaddingTop {
  padding-top: 100px;
}

.globbalPaddingBottom {
  padding-bottom: 100px;
}

.globalBlockPaddings {
  padding: 100px 0 100px 0;
}

.globalBlockMargins {
  margin: 100px 0 100px 0;
}

.globalBlackColor {
  color: rgb(15, 15, 15);
}

.globalGrayColor {
  color: rgb(78, 78, 78);
}

.globalWhiteColor {
  color: white;
}

.globalTitle {
  font-size: 2rem;
  font-weight: 600;
}

.globalSubtitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.globalText {
  font-size: 1.2rem;
  font-weight: 300;
}

@media (max-width: 670px) {
  .globalText {
    font-size: 1rem;
  }

  .globalBlockPaddings {
    padding: 50px 0 50px 0;
  }

  .globbalPaddingTop {
    padding-top: 50px;
  }

  .globbalPaddingBottom {
    padding-bottom: 50px;
  }

  .globalBlockMargins {
    margin: 50px 0 50px 0;
  }
}

@media (max-width: 535px) {
  .globalTitle {
    font-size: 1.4rem;
  }
}

@media (max-width: 510px) {
  .mainActionButton {
    a {
      padding: 12px;
    }
  }
}

@media only screen and (max-width: 500px) {
  body {
    -webkit-animation: none !important;
    animation: none !important;
  }
}

@media (max-width: 390px) {
  .globalTitle {
    font-size: 1.3rem;
  }

  .globalSubtitle {
    font-size: 1rem;
  }
}
