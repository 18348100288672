.servicesFlexFrame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-box-pack: space-evenly;
  justify-content: space-evenly;
}

.fasilPaddings {
  padding-bottom: 50px;
}

.visualPaddings {
  padding-top: 50px;
  padding-bottom: 100px;
}

.vsualNotesFrame {
  background: black;
  -webkit-clip-path: polygon(
    0 0,
    23% 4%,
    46% 2%,
    67% 4%,
    86% 2%,
    100% 0,
    100% 99%,
    76% 97%,
    52% 99%,
    33% 97%,
    16% 99%,
    0% 100%
  );
  clip-path: polygon(
    0 0,
    23% 4%,
    46% 2%,
    67% 4%,
    86% 2%,
    100% 0,
    100% 99%,
    76% 97%,
    52% 99%,
    33% 97%,
    16% 99%,
    0% 100%
  );
  padding-bottom: 100px;
}

.servicesLeftBox {
  width: 40%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.servicesRightBox {
  width: 40%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.positionFixes {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.servicesTextBox {
  max-width: 550px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.servicesTextDevider {
  height: 1px;
  width: 200px;
  background: gray;
  margin: 20px 0;
}

.servicesDescriptionBox {
  text-align: center;
  h3 {
    padding-bottom: 25px;
  }
}

.servicesGalleryFrame {
  max-width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  gap: 25px;
  padding-top: 0;
}

.leftFrame {
  padding-top: 50px;
  width: 50%;
}

.rightFrame {
  width: 50%;
}

.visualBoxFrame {
  height: 400px;
  cursor: pointer;
  background: orange;
  position: relative;
  border-radius: 4px;
  margin-bottom: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}

.servicesOverlay {
  background: rgba(0, 0, 0, 0.836);
  position: fixed;
  z-index: 5500;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
  opacity: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;

  .fa-times-circle {
    font-size: 2.3rem;
    cursor: pointer;
    position: absolute;
    right: 5.9%;
    top: -8%;
  }
}

.visualNotesFrame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  max-width: 1500px;
  .vsImageBox {
    border-radius: 4px;
    background: floralwhite;
  }
}

.visualNotesImageDevider {
  margin-bottom: 100px;
  height: 400px;
  width: 100%;
  background: url('/src/resources/img/dualEducationUzbekistan2.jpg') 50% -7%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1265px) {
  .visualNotesFrame {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
  }
}

.showServicesOverlay {
  opacity: 1;
  pointer-events: auto;
}

.presentation1 {
  background: url('/src/resources/img/visualNotes/extraDemo/selfimprovementPresentation.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.presentation2 {
  background: url('/src/resources/img/visualNotes/extraDemo/DPpresentation.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.presentation3 {
  background: url('/src/resources/img/visualNotes/extraDemo/designerFearsPresentation.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.presentation4 {
  background: url('/src/resources/img/visualNotes/extraDemo/differancesGDvsDPPresentation.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.visualBoxFrame:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(-5px) translateX(-5px);
  transform: translateY(-5px) translateX(-5px);
}
.graphicEscort {
  margin: 0 auto;
  max-width: 900px;
}
@media (max-width: 1202px) {
  .visualBoxFrame {
    margin: 0 auto 25px auto;
  }
  .servicesPositionOrderLast {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -moz-box-ordinal-group: 3;
    order: 2;
  }
  .servicesFlexFrame {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
  }

  .servicesTextBox {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
    padding-right: 0;
  }

  .servicesLeftBox {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
  }

  .servicesRightBox {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .servicesGalleryFrame {
    display: none;
  }
  .visualNotesImageDevider {
    margin-bottom: 0;
  }
  .rightFrame {
    width: 80%;
  }
  .leftFrame {
    width: 80%;
    padding-top: 0;
  }
  .visualBoxFrame {
    height: 200px;
  }

  .vsualNotesFrame {
    -webkit-clip-path: polygon(
      0 0,
      25% 1%,
      50% 0,
      73% 1%,
      100% 0,
      100% 100%,
      74% 99%,
      50% 100%,
      24% 99%,
      0 100%
    );
    clip-path: polygon(
      0 0,
      25% 1%,
      50% 0,
      73% 1%,
      100% 0,
      100% 100%,
      74% 99%,
      50% 100%,
      24% 99%,
      0 100%
    );
  }
}

@media (max-width: 637px) {
  .visualNotesImageDevider {
    display: none;
  }
  .servicesDescriptionBox {
    padding-bottom: 0;
  }
}
