.nav-frame {
  position: fixed;
  z-index: 5;
  width: 100%;
  border-bottom: 1px solid transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding: 25px 0 25px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;

  .servicesNavIcon {
    font-size: 1.3rem;
    margin-right: 10px;
  }

  li {
    display: inline;
    position: relative;
    margin-left: 70px;
  }
}

.nav-link:after {
  position: absolute;
  content: '';
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 1px;
  width: 0;
  bottom: -5px;
  left: 0;
  background: #413c3ccc;
}

.nav-link:hover:after {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgb(0, 0, 0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: 500;
}

@media (max-width: 770px) {
  .nav-frame {
    display: none;
  }
}

//Navigation scrolled styles

.nav-box-scrolled {
  padding: 7px 0 7px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-frame-scrolled {
  border-bottom: 1px solid #4f5050;
  background: #151414f5;
}

.nav-link-scrolled {
  text-decoration: none;
  color: #bfbfbf;

  -webkit-transition: 0.3s;

  transition: 0.3s;

  &:hover {
    color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}

.jiggleLogoFrame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: 1.6rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.navLogo {
  text-decoration: none;
  color: inherit;
}

.nav-logo-latter {
  font-family: oswald;
  -webkit-transition: 0.05s !important;
  transition: 0.05s !important;
}

.nav-logo-latter-scrolled {
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  color: white !important;
}

.preventLogoJiggle {
  -webkit-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}
