@font-face {
  font-family: 'oswald';
  src: url('/src/resources/fonts/Oswald-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('/src/resources/fonts/Montserrat-ExtraBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('/src/resources/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('/src/resources/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('/src/resources/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('/src/resources/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
