.intro-block-frame {
  position: relative;
}

.intersectionObserverPoint {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0px;
}

.intro-block-paddings {
  padding: 100px 0 100px 0;
}

.intro-block-grid-box {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  position: relative;
}

.intro_block_description_box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  justify-self: center;
  text-align: center;
  max-width: 880px;
  margin-bottom: 40px;

  p {
    width: 83%;
    margin-top: 25px;
  }
}

.intro_block_hero_img {
  width: 100%;
  margin-bottom: 25px;
}

.intro_block_hero_img_mobile {
  width: 100%;
  margin-bottom: 25px;
  display: none;
}

.intro-block-title {
  text-align: center;
  line-height: 110%;
  font-weight: 700;
  font-size: 2.7rem;
}

.intro-block-devider {
  height: 1px;
  background: rgb(207, 207, 207);
  min-width: 50%;
  margin-bottom: 25px;
}

.intro-block-button-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.ibBtnPos {
  margin-right: 20px;
}

@media (max-width: 800px) {
  .intro-block-title {
    font-size: 2.3rem;
  }
}

@media (max-width: 550px) {
  .intro_block_hero_img_mobile {
    width: 100%;
    margin-bottom: 25px;
    display: block;
  }

  .intro_block_hero_img {
    display: none;
  }
}

@media (max-width: 530px) {
  .intro-block-paddings {
    padding: 50px 0 50px 0;
  }

  .intro-block-title {
    font-size: 2rem;
  }
}

@media (max-width: 490px) {
  .intro-block-button-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
  }

  .ibBtnPos {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .intro-block-action-button {
    margin-right: 0;
  }
}
