.swiperServices {
  max-width: 500px;
  min-width: 300px;
  border-radius: 10px;
}

.vendorSwiperFrame {
  max-width: 100%;
  position: relative;
  padding: 0 50px 0 50px;
}

.swiperSlideServices {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  img {
    border-radius: 10px;
    width: 100%;
  }
}

.feedbackSwiperFrame {
  margin: 0 auto;
  padding-bottom: 40px;
  margin-bottom: 100px;
  position: relative;
}

.swiper-slide-next {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feedbackSwiperWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}
.swiper-slide_feedback {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;

  img {
    border-radius: 10px;
  }
}

.swiper-pagination {
  z-index: 1;
}

.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  bottom: -30px;
}

:root {
  --swiper-navigation-size: 45px;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-navigation-color: rgb(255, 102, 0);
  --swiper-theme-color: rgb(117, 234, 255);
  --swiper-pagination-bullet-inactive-color: rgb(156, 156, 156);
}

.swiper-button-next,
.swiper-button-prev {
  z-index: 1;
}

.swiper-button-next {
  right: 4px;
}

.swiper-button-prev {
  left: 4px;
}

.swiper-pagination-bullet {
  height: 8px;
  width: 8px;
}

.visualNotesSwiperFrame {
  position: relative;
  max-width: 90%;
}

.visualNotesSwiperServices {
  max-width: 2000px;
  min-width: 300px;
  border-radius: 4px;
}

.paginationE {
  bottom: -5% !important;
}

.visualNotesSwiperSlideServices {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  cursor: auto;
}

@media (max-width: 600px) {
  :root {
    --swiper-navigation-size: 34px;
  }

  .swiper-pagination-bullet {
    height: 7px;
    width: 7px;
  }

  .vendorSwiperFrame {
    padding: 0 0 60px 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 94%;
  }

  .swiper-button-prev {
    left: 30%;
  }

  .swiper-button-next {
    right: 30%;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 4%;
  }
}
