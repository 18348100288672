.whyUsFrame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-box-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.whyUsCard {
  max-width: 384px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 25px;
  h3 {
    margin-bottom: 25px;
  }

  .krivoiKostil {
    line-height: 35px;
  }

  p {
    width: 80%;
  }

  img {
    margin-bottom: 25px;
  }
}

.whyUsBlock_contacts_box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;

  h2 {
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}

@media (max-width: 776px) {
  .whyUsCard:nth-child(2) {
    border-top: 1px gray solid;
    padding: 50px 0;
    margin: 50px 0;
    border-bottom: 1px gray solid;
  }

  .whyUsCard {
    p {
      width: 100%;
    }
  }
}
